.footer {
    /* position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    text-align: center; */
    flex: 0 0 auto;
    width: 100%;
    background-color: #f2f2f2;
    text-align: center;
    margin-bottom: 0;
  }

  .ot-sdk-btn {
    text-align: center;
    background: #141417;
    border: none;
      border-top-width: medium;
      border-top-style: none;
      border-top-color: currentcolor;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 12px;
    width: auto;
    display: block;
    position: relative;
  }

  .ot-sdk-btn button::after, .ot-sdk-btn [type="button"]::after, .cmp-modal button::after, .cmp-modal [type="button"]::after {
    content: unset;
    position: unset;
    display: unset;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    border: unset;
    border-radius: unset;
    transition: unset;
  }

  .ot-sdk-btn .ot-sdk-show-settings::before {
    content: "";
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjQuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNTYgMjU2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyNTYgMjU2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTE0MS4zLDIyLjZjNTguMiw3LjksOTksNjEuNSw5MS4xLDExOS43Yy02LjUsNDcuOS00NC41LDg1LjQtOTIuNiw5MS4zYy00LDAtOC4xLDAuNi0xMi41LDAuNgoJCWMtNTMuOC0wLjEtOTkuMS00MC4zLTEwNS41LTkzLjdjOC4zLTAuMSwxNi41LTEuOSwyNC4xLTUuMmMyMC43LTkuMywzNS0yOC44LDM3LjUtNTEuNGM2LjctMC42LDEzLjMtMi4zLDE5LjUtNQoJCWMyMi42LTkuOSwzNy41LTMxLjksMzguMi01Ni41IE0xMjcuMywzaC0zLjljLTEuOSwwLjEtMy4zLDEuOC0zLjEsMy42YzAsMC4yLDAuMSwwLjQsMC4xLDAuNmM2LjgsMjEuOS00LDQ1LjUtMjUsNTQuNgoJCWMtNS41LDIuNC0xMS41LDMuNi0xNy41LDMuNmMtMy4zLDAtNi42LTAuNC05LjctMS4xaC0wLjhjLTEuNywwLTMuMiwxLjMtMy4yLDMuMWMwLDAuMiwwLDAuNSwwLjEsMC43YzUsMjQuMi0xMC42LDQ3LjktMzQuOCw1Mi44CgkJYy0yLjksMC42LTUuOCwwLjktOC44LDAuOWMtNS42LDAtMTEuMi0xLjEtMTYuNS0zLjFsMCwwYy0wLjUtMC4xLTEsMC4zLTEuMSwwLjljMCwwLDAsMC4xLDAsMC4xYzAsMi43LDAsNS41LDAsOC4yCgkJYzAsNjguNyw1NS41LDEyNC42LDEyNC4yLDEyNWM0LjgsMCw5LjUtMC4zLDE0LjItMC43YzY4LjYtNy41LDExOC4xLTY5LjMsMTEwLjYtMTM3LjlDMjQ1LjIsNTAuNywxOTEuMywyLjcsMTI3LjMsM3oiLz4KCTxjaXJjbGUgY3g9IjEyNy4yIiBjeT0iMTI4LjEiIHI9IjE3LjIiLz4KCTxjaXJjbGUgY3g9IjE2Ni42IiBjeT0iNzAuMyIgcj0iMTcuMSIvPgoJPGVsbGlwc2UgdHJhbnNmb3JtPSJtYXRyaXgoMC4xNjAyIC0wLjk4NzEgMC45ODcxIDAuMTYwMiAyMy4xMzMxIDMwOC4xOTA5KSIgY3g9IjE5Mi43IiBjeT0iMTQwLjUiIHJ4PSIxNy4yIiByeT0iMTcuMiIvPgoJPGNpcmNsZSBjeD0iMTM5LjQiIGN5PSIxOTMuNiIgcj0iMTcuMiIvPgoJPGNpcmNsZSBjeD0iNjkuMyIgY3k9IjE2Ny42IiByPSIxNy4xIi8+CjwvZz4KPC9zdmc+Cg==");
    background-size: cover;
    background-position: center;
    position: relative;
    vertical-align: top;
    height: 14px;
    width: 14px;
    display: inline-block;
    margin: -2px 6px;
    filter: invert(100%);
  }

  .ot-sdk-btn .ot-sdk-show-settings {
    background: transparent;
    color: #f6f6f9;
    font-family: XfinityBrown, DMSans, sans-serif;
    font-size: 14px;
    line-height: 14px;
    border: none;
    padding: 2px;
    outline: #62626c00 solid 2px;
    outline-offset: 0px;
    transition: all 0.2s;
  }

  .ot-sdk-btn button, .ot-sdk-btn [type="button"], .cmp-modal button, .cmp-modal [type="button"] {
    font-size: unset;
    position: unset;
    transition: unset;
    display: unset;
    margin-bottom: unset;
    min-width: unset;
    padding: unset;
    line-height: unset;
    text-align: unset;
    color: unset;
    border-radius: unset;
    font-weight: unset;
    text-decoration: unset;
  }


  