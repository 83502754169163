.ascendon a {
    color: #2B9CD8;
  }

  .ascendon input.invalid,
  .ascendon select.invalid {
    border: 1px solid #f00;
  }
  .ascendon .row.spacing-top > div:first-child > *:first-child {
    margin-top: 15px;
  }
  .ascendon .only-top-bottom-margin {
    margin: 10px 0 10px 0;
  }
  .ascendon .media {
    position: relative;
  }
  .ascendon #ascendon-address-list .panel-body {
    position: relative;
  }
  .ascendon .recaptcha-container {
    transform-origin: 20px 0;
  }
  @media (min-width: 992px) {
    .ascendon .recaptcha-container {
      transform: scale(1.13);
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .ascendon .recaptcha-container {
      transform: scale(0.93);
    }
  }
  @media (min-width: 480px) and (max-width: 767px) {
    .ascendon .recaptcha-container {
      transform: scale(1.11);
    }
  }
  @media (min-width: 435px) and (max-width: 479px) {
    .ascendon .recaptcha-container {
      transform: scale(1.23);
    }
  }
  @media (min-width: 411px) and (max-width: 434px) {
    .ascendon .recaptcha-container {
      transform: scale(1.15);
    }
  }
  @media (min-width: 375px) and (max-width: 410px) {
    .ascendon .recaptcha-container {
      transform: scale(1.01);
    }
  }
  @media (min-width: 360px) and (max-width: 374px) {
    .ascendon .recaptcha-container {
      transform: scale(0.98);
    }
  }
  @media (min-width: 320px) and (max-width: 359px) {
    .ascendon .recaptcha-container {
      transform: scale(0.839);
    }
  }
  @media (max-width: 319px) {
    .ascendon .recaptcha-container {
      transform: scale(0.75);
    }
  }
  .ascendon .embedded .recaptcha-container {
    transform-origin: 20px 0;
  }
  @media (min-width: 992px) {
    .ascendon .embedded .recaptcha-container {
      transform: scale(0.8136);
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .ascendon .embedded .recaptcha-container {
      transform: scale(0.6696);
    }
  }
  @media (min-width: 480px) and (max-width: 767px) {
    .ascendon .embedded .recaptcha-container {
      transform: scale(0.7992);
    }
  }
  @media (min-width: 435px) and (max-width: 479px) {
    .ascendon .embedded .recaptcha-container {
      transform: scale(0.8856);
    }
  }
  @media (min-width: 411px) and (max-width: 434px) {
    .ascendon .embedded .recaptcha-container {
      transform: scale(0.828);
    }
  }
  @media (min-width: 375px) and (max-width: 410px) {
    .ascendon .embedded .recaptcha-container {
      transform: scale(0.7272);
    }
  }
  @media (min-width: 360px) and (max-width: 374px) {
    .ascendon .embedded .recaptcha-container {
      transform: scale(0.7056);
    }
  }
  @media (min-width: 320px) and (max-width: 359px) {
    .ascendon .embedded .recaptcha-container {
      transform: scale(0.60408);
    }
  }
  @media (max-width: 319px) {
    .ascendon .embedded .recaptcha-container {
      transform: scale(0.54);
    }
  }
  .ascendon .recaptcha-container.has-error > div:first-child > div:first-child {
    border: 1px solid #D0021B;
  }
  .ascendon .ascendon-link-paypal-payment-form .payment-link-proceed {
    background-color: #004480;
    border-color: #004480;
  }
  .ascendon .ascendon-link-paypal-payment-form .payment-link-proceed:hover {
    background-color: #00294d;
  }
  .ascendon .payment-list-template-details .expired-date {
    color: red;
  }
  .ascendon .disabled-overlay::after {
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: .7;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
  }
  .ascendon .payment-address-select-form {
    display: none;
  }
  .ascendon ul {
    list-style-type: none;
  }
  .ascendon input.invalid,
  .ascendon select.invalid {
    border: 1px solid #f00;
  }
  .ascendon #order-history-filters {
    margin-bottom: 20px;
  }
  .ascendon #no-orders {
    text-align: center;
  }
  .ascendon #no-orders img {
    min-height: 200px;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
  }
  .ascendon #no-orders span {
    color: #696969;
  }
  .ascendon .no-right-padding {
    padding-right: 0;
  }
  .ascendon .no-left-padding {
    padding-left: 0;
  }
  .ascendon s {
    margin-right: 5px;
  }
  .ascendon .order_summary_header,
  .ascendon .payment_details_header,
  .ascendon .shipping_address_header,
  .ascendon .shipping_method_header {
    padding-bottom: 10px;
  }
  @media (min-width: 480px) {
    .ascendon .right-edge-column {
      margin-right: 15px;
    }
  }
  .ascendon #ascendon-order-history-header {
    margin: 0 0 20px 0;
    border-bottom: 3px solid #AAAAAA;
  }
  .ascendon .order-list-item {
    margin-top: 5px;
  }
  .ascendon .order-list-item-row {
    margin-bottom: 10px;
  }
  .ascendon .order-list-item-parent-row {
    margin-bottom: 20px;
  }
  .ascendon .order-list-item-parent-row .indented-bundle-row {
    margin-left: 20px;
  }
  .ascendon .order-list-item-parent-row .indented-bundle-row .bundle-child-item {
    padding-top: 10px;
  }
  .ascendon .error {
    padding: 10px 3px;
  }
  @media screen and (device-aspect-ratio: 2/3) {
    .ascendon .container {
      width: auto;
    }
    .ascendon .order-list-item-right {
      width: 100%;
      display: block;
      text-align: left;
    }
    .ascendon .order-list-item-left {
      width: 100%;
      display: block;
    }
    .ascendon .order-list-display-amount {
      width: 10%;
    }
    .ascendon #no-orders {
      width: auto;
    }
  }
  @media screen and (device-aspect-ratio: 40/71) {
    .ascendon .container {
      width: auto;
    }
    .ascendon .order-list-item-right {
      width: 100%;
      display: block;
      text-align: left;
    }
    .ascendon .order-list-item-left {
      width: 100%;
      display: block;
    }
    .ascendon .order-list-display-amount {
      width: 10%;
    }
    .ascendon #no-orders {
      width: auto;
    }
  }
  @media screen and (device-aspect-ratio: 667/375) {
    .ascendon .container {
      width: auto;
    }
    .ascendon .order-list-item-right {
      width: 100%;
      display: block;
      text-align: left;
    }
    .ascendon .order-list-item-left {
      width: 100%;
      display: block;
    }
    .ascendon .order-list-display-amount {
      width: 10%;
    }
    .ascendon #no-orders {
      width: auto;
    }
  }
  .ascendon .order-item-thumbnail-container {
    width: 100%;
    box-shadow: 1px 1px 5px 0 #333;
    cursor: pointer;
  }
  .ascendon .order-child-item-thumbnail-container {
    box-shadow: 1px 1px 5px 0 #333;
    cursor: pointer;
  }
  .ascendon .child-pricing {
    margin-left: -1.5em;
  }
  .ascendon .order-item-thumbnail-container:hover {
    box-shadow: 1px 1px 5px 0 #006699;
  }
  .ascendon .order-item-product-name {
    cursor: pointer;
  }
  .ascendon .order-details {
    transition: opacity 0.5s;
    overflow: visible;
    margin: 10px 0 0 0;
  }
  .ascendon .order-status-complete {
    color: #2fce3a;
  }
  .ascendon .order-status-pending {
    color: red;
  }
  .ascendon .address-list-template-details :first-child {
    margin-top: 0;
  }
  @media only screen and (max-width: 479px) {
    .ascendon .order-list-item {
      font-size: 0.8em;
    }
    .ascendon .order-list-item .indented-bundle-row {
      margin-left: -10px;
    }
  }
  .ascendon .orderDate {
    padding-bottom: 10px;
  }
  @media (min-width: 480px) {
    .ascendon .orderDate {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .ascendon .order-list-view-details .btn-link {
    color: inherit;
    font-size: inherit;
  }
  .ascendon .order-list-view-details i[class*="fa-caret"] {
    padding: 4px 2px;
  }
  .ascendon .order-item-pricing-plan-name {
    font-size: 0.8em;
  }
  .ascendon .btn.btn-link-info.view-details.expand,
  .ascendon .btn.btn-link-info.view-details.collapse {
    padding-right: 0;
  }
  .order-pager {
    cursor: default;
  }
  .order-pager span {
    vertical-align: middle;
  }
  .order-pager-current-page,
  .page-disabled {
    cursor: default;
    padding: 4px 8px;
    vertical-align: middle;
    color: gray;
  }
  .order-pager {
    font-size: 14px;
  }
  .ascendon .tax-item-list {
    margin-bottom: 4px;
  }
  .ascendon .tax-item-list .tax-item {
    font-size: 0.8em;
  }
  .ascendon .order-details-middle .green {
    color: #2fce3a;
  }
  .ascendon .order_summary_header {
    margin: 10px 0 0 0;
  }
  .ascendon .shipping-method {
    margin-top: 5px;
    padding-bottom: 15px;
  }
  .ascendon .product-border-separating {
    height: 1px;
    background-color: #c4c2c2;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  @media screen and (device-aspect-ratio: 2/3) {
    .ascendon .wrapping-column-device-view {
      margin-top: 20px;
    }
  }
  @media screen and (device-aspect-ratio: 40/71) {
    .ascendon .wrapping-column-device-view {
      margin-top: 20px;
    }
  }
  @media screen and (device-aspect-ratio: 667/375) {
    .ascendon .wrapping-column-device-view {
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 479px) {
    .ascendon .wrapping-column-device-view {
      margin-top: 20px;
    }
  }
  .ascendon .margin-row {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .ascendon .page-button {
    cursor: pointer;
  }
  .ascendon p {
    margin: 0;
  }
  .ascendon #no-subscriptions image {
    margin: 25px 0 10px 0;
  }
  .ascendon .no-subscriptions-bg {
    font-size: 20px;
    color: #8C8C8C;
  }
  .ascendon #ascendon-subscriptions-list {
    margin-bottom: 25px;
  }
  .ascendon #ascendon-subscriptions-list .paypal-button.paypal-style-checkout.paypal-color-gold .paypal-button-content {
    background: none;
    text-shadow: none;
  }
  .ascendon #ascendon-subscriptions-list .paypal-button.paypal-style-tertiary .paypal-button-content:after {
    background: none;
  }
  .ascendon #ascendon-subscriptions-list .paypal-button.paypal-style-tertiary .paypal-button-content {
    border: none;
    background: none;
  }
  .ascendon #ascendon-subscriptions-list button.paypal-button {
    border: 1px solid #696969;
    background-color: #E0E0E0;
  }
  .ascendon #ascendon-subscriptions-list button.paypal-button:hover {
    background-color: #F0F0F0;
  }
  .ascendon #ascendon-subscriptions-list h3.subscription-list-item-header {
    margin: 0 0 20px 0 !important;
  }
  .ascendon #ascendon-subscriptions-list .subscription-smaller-text,
  .ascendon #ascendon-subscriptions-list .subscription-smaller-text label {
    font-size: 0.9em;
  }
  .ascendon #ascendon-subscriptions-list .subscription-divider {
    margin: 21px 30px;
  }
  .ascendon #ascendon-subscriptions-list .original-renewal-amount {
    text-decoration: line-through;
    margin-right: 5px;
    color: #AAAAAA;
  }
  .ascendon .subscription-list-item label {
    margin: 0;
  }
  .ascendon .subscription-list-item span {
    font-size: 0.85em;
  }
  .ascendon .subscription-list-item .subscription-suspended,
  .ascendon .subscription-list-item .expired-date,
  .ascendon .subscription-list-item .cancel-subscription {
    color: red;
  }
  .ascendon .subscription-list-item .subscription-active {
    color: #32cd32;
  }
  .ascendon .subscription-list-item h2 {
    font-size: 1.1em;
    margin-top: 5px;
    font-weight: bold;
  }
  .ascendon .subscription-list-item .panel-heading {
    padding: 0 10px;
  }
  .ascendon .subscription-list-item .dropdown-menu-right {
    right: 15px;
    left: auto;
  }
  .ascendon .cancel-reason {
    margin-top: 5px;
  }
  .ascendon .subscription-list-template-details label {
    font-weight: normal;
  }
  .ascendon .plan-options-button {
    padding: 0 8px;
  }
  .ascendon .subscription-option-button {
    cursor: pointer;
    font-size: 0.85em;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 5px;
  }
  .ascendon .full-width-top-button {
    margin-bottom: 10px;
  }
  .ascendon .subscription-change .subscription-options-list .current-plan-icon i {
    color: #32cd32;
    font-size: 13px;
  }
  .ascendon .subscription-change .subscription-options-list .current-plan-icon span {
    color: #999;
  }
  .ascendon .payment-list-template-details h4 {
    margin-right: 20px;
  }
  .ascendon .payment-list-template-details p {
    color: #666666;
  }
  .ascendon .payment-list-template-details .expired-date {
    color: red;
  }
  .ascendon .payment-list-template-details .default-payment-instrument-visible {
    margin-top: 10px;
  }
  .ascendon .payment-list-template-details .default-payment-instrument-not-visible {
    margin-top: 10px;
    visibility: hidden;
  }
  .ascendon .payment-list-template-details .itunes-payment-change {
    margin-top: -10px;
    font-size: 12px;
  }
  .ascendon .subscription-payment-close {
    height: 32px;
  }
  .ascendon .no-payment-bg i {
    color: gray;
    display: block;
    font-size: 50px;
  }
  .ascendon #ascendon-subscriptions-list > div:nth-of-type(odd) #ascendon-payment-list > div:nth-of-type(odd) {
    background-color: #FFFFFF;
  }
  .ascendon [id^=ascendon-subscription-payment] .container {
    width: auto;
  }
  .ascendon .list-item-wrapper {
    padding-top: 20px;
  }
  .ascendon .default-checkbox-container i {
    cursor: pointer;
  }
  .ascendon .default-checkbox-container .fa-check-square-o {
    color: #32CD32;
  }
  .ascendon .default-item-icon {
    padding-right: 7px;
    color: #32CD32;
  }
  .ascendon .payment-icon {
    margin-right: 4px;
  }
  .ascendon p {
    margin: 0;
  }
  .ascendon #no-devices i {
    margin: 25px 0 10px 0;
    font-size: 100px;
  }
  .ascendon .no-devices-bg {
    font-size: 20px;
    color: #8C8C8C;
  }
  .ascendon #ascendon-devices-list {
    margin-bottom: 25px;
  }
  .ascendon .device-item i {
    margin-right: 5px;
  }
  .ascendon .device-icon {
    font-size: 33px;
  }
  .ascendon .icon-roku {
    font-size: 13px;
  }
  .ascendon .device-edit-form {
    display: none;
  }
  .ascendon .remove-device {
    cursor: pointer;
  }
  .ascendon .device-start-date,
  .ascendon .device-type-name {
    font-size: 0.8em;
  }
  .ascendon .device-nickname {
    word-break: break-all;
  }
  .ascendon .device-edit-form .action-buttons,
  .ascendon .device-edit-form .delete-buttons {
    margin-top: 10px;
  }
  @media (max-width: 767px) {
    .ascendon .device-edit-form .delete-button {
      display: block;
      margin: 22px 0;
    }
  }
  .ascendon #continue-link {
    text-align: center;
  }
  .ascendon .uvregistration-container {
    display: none;
  }
  .ascendon .uvregistration-container .row {
    margin-left: 0;
    margin-right: 0;
  }
  .ascendon .uv-info-container {
    cursor: pointer;
  }
  .ascendon .icon-uv-learn-more {
    height: 35px;
    display: block;
    color: transparent;
    background-image: url('../../../public/resources/images/UVlogo.png');
    background-repeat: no-repeat;
  }
  @media (min-device-width: 320px) and (max-device-width: 500px) {
    .ascendon .icon-uv-learn-more {
      height: 35px;
      display: block;
      color: transparent;
      background-image: url('../../../public/resources/images/UVlogo.png');
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
  .ascendon-container {
    background-color: #EEE;
    border: 1px solid #CCC;
    margin-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .margin-container {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .ascendon .active-uv,
  .ascendon .active-uv:focus,
  .ascendon .active-uv:hover {
    background-color: mediumpurple;
    color: white;
  }
  .ascendon .uv-link-container {
    text-align: center;
  }
  .ascendon .uv-form-container {
    min-height: 200px;
  }
  .ascendon .top-spacer {
    margin-top: 30px;
  }
  .ascendon .uv-password {
    margin-top: 15px;
  }
  .ascendon .uv-continue {
    display: block;
    text-align: center;
  }
  .ascendon iframe {
    border: none !important;
  }
  .ascendon .uv-iframe {
    width: 354px;
    height: 604px;
    display: block;
    margin: 0 auto;
  }
  .ascendon .uv-iframe-container {
    position: relative;
  }
  .ascendon .uv-frame-loader {
    position: absolute;
    left: 48%;
    top: 45%;
  }
  .ascendon .continue-uv-linking {
    display: block;
    text-align: center;
  }
  @media screen and (device-aspect-ratio: 2/3) {
    .ascendon .container {
      width: auto;
    }
  }
  @media screen and (device-aspect-ratio: 40/71) {
    .ascendon .container {
      width: auto;
    }
  }
  @media screen and (device-aspect-ratio: 667/375) {
    .ascendon .container {
      width: auto;
    }
  }
  .ascendon #progress {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: none;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    /*dim the background*/
  }
  .ascendon #progress div.row {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ascendon .italic-text {
    font-style: italic;
  }
  .ascendon .horizontal-edit-button {
    margin-top: 18px !important;
  }
  .ascendon .stacked-input {
    margin-top: 8px;
  }
  .ascendon .panel-body {
    position: relative;
  }
  .ascendon .disabling-div {
    display: none;
    z-index: 1001;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: .7;
    filter: alpha(opacity=70);
  }
  .ascendon .col-centered {
    float: none;
    margin: 0 auto;
  }
  .ascendon .row.indented-row {
    margin-left: 2px !important;
  }
  .ascendon input.static-input {
    border: none !important;
    padding: 0 !important;
    background-color: #ffffff !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -o-box-shadow: none !important;
    font-size: 14px !important;
    color: #676767 !important;
    margin-top: -5px !important;
  }
  .ascendon .default-shipping-address {
    margin-top: 8px;
  }
  .ascendon .default-item-icon {
    padding-right: 7px;
    color: #32CD32;
  }
  .ascendon .list-item-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .ascendon .red {
    color: #DD0000;
  }
  .ascendon .base-header {
    border-bottom: 3px solid #E0E0E0;
  }
  .ascendon .base-header h1 {
    color: #8C8C8C;
  }
  .ascendon .error-message-icon {
    margin-top: 2px;
  }
  .ascendon .validation-message {
    margin: 5px 0 5px 0;
  }
  .ascendon .validation-container {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .ascendon .no-gutter {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ascendon input:-webkit-autofill,
  .ascendon input:-webkit-autofill:hover,
  .ascendon input:-webkit-autofill:focus,
  .ascendon input:-webkit-autofill:active {
    transition: background-color 600s ease-in-out 0s !important;
  }
  
  a {
    color: #0272b6;
    cursor: pointer !important;
    text-decoration: none !important;
}

.ascendon ul, .ascendon ol {
    margin-top: 0;
    margin-bottom: 0px !important;
}


.ng-scope {
  flex: 1;
}

.dpskShow{
  padding-left: 8%; 
  padding-right:8%; 
  padding-top: 20px; 
  padding-bottom:20px;
}

.dpskHide{
  flex: auto;
  display: none;
}