@charset "utf-8";

@font-face {
	font-family: 'XFINITYSansTT-Reg';
	src: local('XFINITYSansTT-Reg'),url('../../../public/resources/css/fonts/XFINITYSansTT-Reg.ttf') format('truetype');

}

@font-face {
    font-family: "XfinitySansIcon";
    src: url("../../../public/resources/css/fonts/xfinity_sans_icons_v1.14.eot");
    src: url("../../../public/resources/css/fonts/xfinity_sans_icons_v1.14.eot?#iefix") format("embedded-opentype"),
         url("../../../public/resources/css/fonts/xfinity_sans_icons_v1.14.woff") format("woff"),
         url("../../../public/resources/css/fonts/xfinity_sans_icons_v1.14.ttf") format("truetype"),
         url("../../../public/resources/css/fonts/xfinity_sans_icons_v1.14.svg#XfinitySansIcon") format("svg");
    font-weight: normal;
    font-style: normal;
}


/* html {
	width: 100%;
	height: 100%;
 	min-height: 100%;
 	margin: 0;
	padding: 0;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
	-moz-opacity: 1.0;
	opacity: 1.0;
    background: url(../../../public/resources/images/XTV-BG.png) no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;	
}

body {
	width: 100%;
	min-height: 100%;
	margin: 0;
	font-family: "XFINITYSansTT-Reg";
	color: #000;
	font-weight: 100;
} */


.ui-widget {
	border: none;
}
.ui-autocomplete {
	overflow-x: hidden;
	overflow-y: scroll;
	display: block;
	position: absolute;
}

.ui-widget-content {
    background: none !important;
	padding-left: 14px;
	padding-right:14px;
}

.ui-menu .ui-menu-item {
	font-family: "XFINITYSansTT-Reg", FontAwesome;
	height: 55px;
	border-bottom: 1px solid #8c8c8c;
	-moz-opacity: 1.0;
	opacity: 1.0;
	padding: 20px 0px 0px 0px;
}
.ui-menu .ui-menu-item a hover{
	border: none;
	color: white;
	border-bottom: 1px solid #8c8c8c;
	background-color: inherit;
}


.ui-menu .ui-menu-item a{
	text-decoration: none;
	border: none;
	color: #e5e5e5;
	display: block;
}




.ui-state-focus {
	font-weight: normal !important;
	border: none !important;
	border-top: 2px solid white !important;
	border-bottom: 2px solid white !important;
	background: none !important;
	color: white !important;
	background-color: none !important;
	padding-left:1px !important;
	padding-bottom:1px !important;
}

.picker-container {
	width: 100%;
    display: flex;
    justify-content: center;
    background: url(../../../public/resources/images/XTV-BG.png);
}

#pickerback {
	float: left;
	padding-left: 14px;
	color: white;
}

#pickerheader {
	margin-top: 20px;
	margin-bottom: 0px;
	height: 60px;
	font-size: 22px;
	color: white;
}

input#picker-input {
    color: rgba(255,255,255,1);
    background-color: rgba(255,255,255,0.25);
    font-family: "XFINITYSansTT-Reg",FontAwesome;
    width: 375px;
    height: 60px;
    font-size: 18px;
    border: none !important;
    display: inline-block;
    outline: none;
    -webkit-appearance: none !important;
    border-radius: 0px;
    padding-left: 5px;
}

.picker-search-web::-webkit-search-cancel-button {
    position: relative;
    right: 10px;
}

.picker-search-android::-webkit-search-cancel-button {
    position: relative;
    right: 10px;
	-moz-appearance: none;
	-webkit-appearance: none;
    height: 20px;
    width: 20px;
    background-image: url(../../../public/resources/images/picker_cancel.png);
}



.errorsection {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 315px;
	height: auto;
}

.icon-badge-nowifi:before {
	font-family: XfinitySansIcon;
  	content: "\e1e0";
  	font-size: 40px;
    color: #fff;
    border-radius: 50%;
    border: solid 2px #fff;
	padding: 18px 14px 10px 14px;
   	display: inline-block;
}

.errorsectionrule {
	margin-top: 26px;
	border: 0px;
	height: 1px;
	width: 46px;
	background: #9b9b9b;
}

.errorsectiontext {
	font-family: XFINITYSansTT-Lgt;
	color: #fff;
	font-size: 18px;
	margin-top: 24px;
}

.errorimage {
	opacity: 0.8;
	filter: alpha(opacity=80);
}


@media only screen and (min-width: 320px) and (max-width: 540px) {	
	input#picker-input {
	    width: 94%;
	}	
}