.inner-column {
    margin: 0 auto;
    max-width: 1024px;
}

.list.channels {
    padding: 56px 128px
}

@media (min-width: 768px) {
    .list.channels tbody tr {
        height: 91px;
    }
}
.list.channels tbody tr {
    border: 1px solid #dde2e6;
    height: 64px;
}

.list.channels thead {
    display: table-header-group;
    text-align: left
}

.list.channels thead th {
    border: 1px solid #dde2e6;
    color: #191919;
    font-family: XSans-regular;
    font-size: .75rem;
    font-weight: 700;
    letter-spacing: .03125rem;
    line-height: 18;
    line-height: 2.375rem;
    padding-left: 17px;
    text-transform: uppercase
}

.list.channels thead .sort {
    cursor: pointer;
    display: inline;
    padding: 10px 15px 10px 10px;
    position: relative
}

.list.channels thead .sort:before {
    background-image: url(../../../public/library/images/svg/icon-arrow.svg);
    background-repeat: no-repeat;
    content: "";
    display: inline-block;
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncA type="table" tableValues="0 0.4" /></feComponentTransfer></filter></svg>#filter');
    -webkit-filter: grayscale(100%) opacity(40%);
    filter: grayscale(100%) opacity(40%);
    height: 12px;
    position: absolute;
    right: 0;
    top: 10px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    width: 8px
}

.list.channels thead .sort[data-order=desc]:before {
    top: 12px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.list.channels thead .sort[data-order=asc]:before,.list.channels thead .sort[data-order=desc]:before {
    -webkit-filter: initial;
    filter: none
}

.list.channels thead .number {
    width: 107px
}

.list.channels thead .available {
    width: 200px
}

.list.channels tbody tr {
    height: 91px
}

.list.channels tbody td.network {
    background: linear-gradient(90deg,#c7c7c7 136px,transparent 0)
}

.list.channels tbody td.network .logo {
    width: 136px
}

.list.channels tbody td.number {
    padding-right: 45px
}

.list.channels tbody td.available {
    padding: 0 30px 0 20px;
    text-align: center
}

.list.channels tbody td.watch .large {
    border: 1px solid #0272b6;
    border-radius: 40px;
    color: #0272b6;
    padding: 10px 20px
}

.list.channels tbody td.watch .large:hover {
    background-color: #00619a;
    color: #fff
}

.list.channels tbody td.watch .large:active {
    background-color: #015383;
    color: #fff
}

.list.channels tbody td.upgrade .large {
    background-color: #0272b6;
    border: none;
    border-radius: 40px;
    color: #fff;
    padding: 10px 20px
}

.list.channels tbody td.upgrade .large:hover {
    background-color: #00619a;
    color: #fff
}

.list.channels tbody td.upgrade .large:active {
    background-color: #015383;
    color: #fff
}

.list.channels tbody td button.large {
    display: inline-block;
    width: 100%
}

.list.channels .clear-search {
    margin-bottom: 43px;
    margin-top: 43px
}

.list.channels .disclaimer {
    padding: 0
}
