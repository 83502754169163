.ascendon .order-summary-container .small-text {
    font-size: 0.8em;
  }
  .ascendon .order-summary-row {
    margin-bottom: 10px;
  }
  .ascendon .order-item {
    margin-bottom: 20px;
  }
  .ascendon .order-item .order-item-details-right {
    padding-left: 0;
  }
  .ascendon .order-item .remove-order-item-prompt {
    margin-top: 4px;
  }
  .ascendon .order-item .remove-order-item-prompt .remove-order-item-prompt-text {
    margin-bottom: 10px;
  }
  .ascendon .order-item-parent {
    margin-bottom: 10px;
  }
  .ascendon .thumbnail-container {
    padding-right: 0;
  }
  .ascendon .order-items {
    margin-bottom: 20px;
  }
  .ascendon .subscription-quote {
    margin-top: 10px;
  }
  .ascendon .row-line {
    width: 100%;
    background-color: #ccc;
    height: 1px;
    margin: 10px auto;
  }
  .ascendon .order-child-item {
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .ascendon .order-child-item img {
    max-width: 75%;
    float: right;
  }
  .ascendon .address-list-template-details :first-child {
    margin-top: 0;
  }
  .ascendon .order-summary-credit {
    color: #FF0000;
  }
  .ascendon .total-value {
    font-size: 1.2em;
  }
  .ascendon .purchase-rewards-value {
    color: #ff9900;
    font-weight: bold;
  }
  .ascendon .order-summary-line-item {
    line-height: 1.5em;
  }
  .ascendon .product-price-del {
    text-decoration: line-through;
  }
  .ascendon .terms-and-conditions-container label {
    padding-left: 8px;
    font-size: 0.8em;
  }
  @media (min-width: 992px) {
    .ascendon .terms-and-conditions-container label {
      min-height: 0;
    }
  }
  .ascendon .terms-and-conditions-container .terms-and-conditions-link {
    text-align: left;
    white-space: normal;
  }
  @media (min-width: 992px) {
    .ascendon .terms-and-conditions-container .terms-and-conditions-link {
      padding: 0 8px;
    }
  }
  .ascendon .terms-and-conditions-list {
    margin-bottom: 20px;
  }
  .ascendon .checkbox {
    margin-top: 0;
  }
  .ascendon .checkbox label {
    line-height: 16px;
  }
  .ascendon .complete-order {
    height: 44px;
  }
  .ascendon #ascendon-submit-order-top-left {
    display: none;
  }
  .ascendon #ascendon-submit-order-top-right {
    display: block;
  }
  .ascendon #ascendon-submit-order-bottom-right {
    display: none;
  }
  @media (max-width: 991px) {
    .ascendon #ascendon-submit-order-top-left {
      display: block;
    }
    .ascendon #ascendon-submit-order-top-right {
      display: none;
    }
    .ascendon #ascendon-submit-order-bottom-right {
      display: block;
    }
  }
  .ascendon .base-header {
    margin-bottom: 20px;
  }
  .ascendon ul {
    list-style-type: none;
  }
  .ascendon input.invalid,
  .ascendon select.invalid {
    border: 1px solid #f00;
  }
  .ascendon #linkToPayPalButton {
    margin: 30px 0;
  }
  .ascendon .paypal-button.paypal-style-checkout.paypal-color-gold .paypal-button-content {
    background: none;
    text-shadow: none;
  }
  .ascendon .paypal-button.paypal-style-tertiary .paypal-button-content:after {
    background: none;
  }
  .ascendon .paypal-button.paypal-style-tertiary .paypal-button-content {
    border: none;
    background: none;
  }
  .ascendon button.paypal-button {
    border: 1px solid #696969;
    background-color: #E0E0E0;
  }
  .ascendon button.paypal-button:hover {
    background-color: #F0F0F0;
  }
  .ascendon #progress {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: none;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    /*dim the background*/
  }
  .ascendon #progress div.row {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ascendon .row .radio-toggle {
    margin-bottom: 15px;
  }
  .ascendon .radio-toggle {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px 0 8px 40px;
  }
  .ascendon .radio-toggle.active {
    background-color: #f3fcfc;
  }
  .ascendon .add-payment-instrument-form #active-form .payment-link-proceed {
    background-color: #004480;
    border-color: #004480;
  }
  .ascendon .add-payment-instrument-form #active-form .payment-link-proceed:hover {
    background-color: #00294d;
  }
  .ascendon #activeform .recaptcha-container,
  .ascendon .payment-select-list .recaptcha-container {
    transform-origin: 20px 0;
    transform: scale(1.14);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .ascendon #activeform .recaptcha-container,
    .ascendon .payment-select-list .recaptcha-container {
      transform: scale(1);
    }
  }
  @media (max-width: 382px) {
    .ascendon #activeform .recaptcha-container,
    .ascendon .payment-select-list .recaptcha-container {
      transform: scale(0.88);
    }
  }
  @media (max-width: 319px) {
    .ascendon #activeform .recaptcha-container,
    .ascendon .payment-select-list .recaptcha-container {
      transform: scale(0.6);
    }
  }
  .ascendon .external-gift-card-group {
    background-color: #F5F5F5;
    margin-bottom: 30px;
  }
  .ascendon .external-gift-card-form {
    margin-top: 10px;
  }
  .ascendon .external-gift-card-group i {
    margin-right: 4px;
  }
  .ascendon div#ascendon-order-external-gift-card-list .external-gift-card-form .row.action-buttons .form-group {
    margin-bottom: 5px;
  }
  .ascendon .payment-list-template-details h4 {
    margin-right: 20px;
  }
  .ascendon .payment-list-template-details p {
    color: #666666;
  }
  .ascendon .payment-list-template-details span:first-child {
    margin-bottom: 10px;
  }
  .ascendon .payment-list-template-details .expired-date {
    color: red;
  }
  .ascendon .payment-list-template-details .default-payment-instrument-visible {
    margin-top: 10px;
  }
  .ascendon .payment-list-template-details .default-payment-instrument-not-visible {
    margin-top: 10px;
    visibility: hidden;
  }
  .ascendon .payment-header {
    margin-right: 20px;
  }
  .ascendon .payment-header span {
    color: #545454;
    font-size: 18px;
    padding: 6px 12px;
  }
  .ascendon .payment-header h2 {
    margin-top: 0;
  }
  .ascendon .payment-header hr {
    margin-top: 0px;
  }
  .ascendon #ascendon-payment-header {
    padding-bottom: 20px;
  }
  .ascendon .no-payment-bg i {
    color: gray;
    display: block;
    font-size: 50px;
  }
  .ascendon #ascendon-order-payments .expired-cc {
    font-style: italic;
    color: red;
  }
  .ascendon #ascendon-order-payments .payment-notes {
    margin-top: 8px;
  }
  .ascendon #ascendon-order-payments .select-payment-instrument-button {
    margin-top: 12px;
  }
  .ascendon #ascendon-order-payments li.list-group-item div.row.credit-card-action-buttons .row.form-group {
    margin-bottom: 5px;
  }
  .ascendon #ascendon-order-payments .c-payment-select-list__cc-icon {
    height: 20px;
    margin: 0 0 5px 0;
  }
  .ascendon .c-credit-card-form__cc-icon-container {
    display: flex;
    align-items: center;
  }
  .ascendon .c-credit-card-form__cc-icon {
    margin-top: 5px;
    height: 31px;
  }
  .c-current-payment-view__cc-icon {
    margin: 0 15px 5px 0;
    height: 20px;
  }
  .ascendon .internal-gift-card-remaining-balance-container span {
    font-size: 0.8em;
  }
  .ascendon .shipping-addresses-container {
    min-height: 180px;
  }
  .ascendon .shipping-address-select-list .address-line-2 {
    margin-top: 8px;
  }
  .ascendon .shipping-address-select-list .default-shipping-address {
    margin-top: 6px;
    color: #888;
  }
  .ascendon .shipping-address-select-list .select-address-button {
    margin-top: 12px;
  }
  .ascendon .ascendon-progress-background {
    padding: 15px;
    background-color: #ddd;
  }
  .ascendon .ascendon-progress-message-container {
    margin-top: 10px;
  }
  .current-email-address {
    word-wrap: break-word;
  }
  .gift-form hr {
    margin-top: 6px;
    margin-bottom: 12px;
  }
  .gift-form .sectionSeparatorMargin {
    margin-top: 20px;
  }
  .gift-form textarea {
    resize: none;
  }
  @font-face {
    font-family: 'XfinityStandardRegular';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Regular.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Regular.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'XfinityStandardBold';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Bold.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Bold.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'XfinityStandard';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Thin.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Thin.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: 'XfinityStandard';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-ThinItalic.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-ThinItalic.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
  }
  @font-face {
    font-family: 'XfinityStandard';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-ExtraLight.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-ExtraLight.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'XfinityStandard';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-ExtraLightItalic.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-ExtraLightItalic.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: 'XfinityStandard';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Light.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Light.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'XfinityStandard';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-LightItalic.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-LightItalic.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'XfinityStandard';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-RegularItalic.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-RegularItalic.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-RegularItalic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'XfinityStandard';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Medium.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Medium.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'XfinityStandard';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-MediumItalic.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-MediumItalic.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: 'XfinityStandard';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-BoldItalic.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-BoldItalic.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard/XfinityStandard-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'XfinityStandardCondensed';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard_Condensed/XfinityStandardCondensed-Medium.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard_Condensed/XfinityStandardCondensed-Medium.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard_Condensed/XfinityStandardCondensed-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'XfinityStandardCondensed';
    src: url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard_Condensed/XfinityStandardCondensed-Bold.woff2") format("woff2"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard_Condensed/XfinityStandardCondensed-Bold.woff") format("woff"), url("https://edge.static-assets.top.comcast.net/staticsites/fonts/latest/Xfinity_Standard_Condensed/XfinityStandardCondensed-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }
  .ascendon .italic-text {
    font-style: italic;
  }
  .ascendon .gray-text {
    color: #8C8C8C;
  }
  .ascendon .error-message-icon {
    margin-top: 2px;
  }
  .ascendon .validation-message {
    margin: 5px 0 5px 0;
  }
  .ascendon .radio input[type=radio] {
    margin-left: 0;
  }
  .ascendon h2,
  .ascendon strong.checkout-product-name {
    font-family: 'XfinityStandardBold', Helvetica, Arial, sans-serif;
    font-weight: 700;
  }
  .ascendon .btn-primary {
    color: #fff;
    background-color: #0272B6 !important;
    border-color: #0272B6 !important;
  }

  @media (min-width: 480px) {
    .ascendon .panel {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
    opacity: .5 !important
  }
  
  .modal-backdrop .in {
    filter: alpha(opacity = 50);
    opacity: .5;
  }
  
  .fa-pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
    animation: fa-spin 1s infinite steps(8);
  }
  .fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }