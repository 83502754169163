

.hero-component h2 {
    color: #191919;
    font-size: 2.5rem;
    line-height: 3.75rem;
    line-height: 4.1875rem;
    margin-bottom: 26px
}

.hero-component h2,.hero-component p {
    color: #fff;
    font-family: XSans-thin;
    letter-spacing: .0125rem
}

.hero-component p {
    font-size: 1.125rem;
    line-height: 1.6875rem;
    margin-bottom: 44px
}

.hero-component .eyebrow {
    font-family: XSans-regular;
    font-size: .9375rem;
    font-weight: 700;
    letter-spacing: .03125rem;
    line-height: 1.375rem;
    text-transform: uppercase
}

.hero-component form {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: 100%
}

.hero-component form input {
    background-color: #fff;
    border: 1px solid #dde2e6;
    border-radius: 3px;
    font-family: XSans-thin;
    padding: 5px 10px;
    width: inherit
}

.hero-component form input:focus::-webkit-input-placeholder {
    color: transparent
}

.hero-component form input:focus::-moz-placeholder {
    color: transparent
}

.hero-component form input:focus:-ms-input-placeholder {
    color: transparent
}

.hero-component form input:focus::placeholder {
    color: transparent
}

.hero-component form button {
    background-image: url(../../images/library/svg/icon-arrow.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 6px;
    height: 15px;
    position: absolute;
    right: 12px;
    top: 11px;
    width: 15px
}

.hero-component form button.active {
    background-image: url(../../images/library/svg/icon-close.svg);
    background-size: 15px
}

.hero-component.login {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    background-image: url(../../images/library/hero/m_Home-Unauthenticated-Hero-767.jpg);
    justify-content: center
}

.hero-component.login p {
    margin-left: -7px;
    margin-right: -7px
}

.hero-component.login form {
    margin-bottom: 50px
}

.hero-component.login input {
    padding-left: 36px
}

.hero-component.login .icon-search-black {
    height: 15px;
    left: 12px;
    position: absolute;
    top: 11px;
    width: 15px
}

.hero-component.channels {
    background-image: url(../../images/library/hero/m_Channels-Hero-767.jpg);
    height: 356px
}

.hero-component.channels,.hero-component.logged-in {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.hero-component.logged-in {
    background-image: url(../../images/library/hero/m_Home-Authenticated-Hero-767-HBO.jpg);
    height: 512px
}

.hero-component.logged-in p {
    margin-bottom: 36px
}

.hero-component.logged-in .link-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.hero-component.logged-in a {
    margin-bottom: 10px
}

.hero-component.logged-in a.hide {
    display: none
}

.hero-component.logged-in a.show {
    display: inherit
}

.hero-component.logged-in.hbo-specific {
    background-image: url(../../images/library/hero/m_Home-Authenticated-Hero-767.jpg)
}

.hero-component.logged-in.wifi-specific {
    background-image: url(../../images/library/hero/wifi_hero.png)
}

.hero-component.video h2,.hero-component.video p {
    color: #000
}

.hero-component video {
    width: 100%
}

.hero-component.video {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    color: #000;
    height: auto;
    justify-content: center
}

.hero-component.video p {
    margin-bottom: 36px
}

.hero-component.video .link-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.hero-component.video a {
    margin-bottom: 10px
}

.hero-component.video a.hide {
    display: none
}

.hero-component.video a.show {
    display: inherit
}
.error form input {
    outline-color: #c90318;
    outline-width: 2px
}

.large {
    font-size: 2.5rem!important
}

.autocomplete-suggestions {
    background-color: #fff;
    border: 1px solid #cfcfcf;
    color: #191919;
    display: none;
    left: 0;
    max-height: 354px;
    position: absolute;
    text-align: left
}

.autocomplete-suggestions.scroll {
    overflow-y: scroll
}

.autocomplete-suggestions a {
    color: #191919;
    display: block;
    padding: 9px
}

.autocomplete-suggestions a.selected,.autocomplete-suggestions a:focus {
    background-color: #e6eaed
}

.modal-content.results {
    padding: 40px 0 20px
}

.modal-content.results h2 {
    -webkit-box-shadow: 0 3px 4px 0 rgba(0,0,0,.09);
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.09);
    padding-bottom: 25px
}

.modal-content.results ul {
    margin-top: 10px;
    text-align: left
}

.modal-content.results ul.scroll {
    height: 295px;
    overflow-y: scroll
}

.modal-content.results ul li {
    padding: 5px 50px
}

.modal-content.results ul li a {
    background-image: url(../../images/library/svg/icon-arrow.svg);
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: 6px;
    padding-right: 19px
}

.modal-content.no-results h2 {
    margin-bottom: 30px
}

.modal-content.no-results p {
    margin-bottom: 36px
}

.modal-content.no-results .btn-back {
    -ms-flex-item-align: center;
    align-self: center;
    background-color: #0272b6;
    border: none;
    border-radius: 40px;
    color: #fff;
    padding: 10px 47px
}

.modal-content.no-results .btn-back:active {
    background-color: #015383;
    color: #fff
}

.modal-content.no-results .btn-back:hover {
    background-color: #00619a;
    color: #fff
}
@media (min-width: 550px) {
    .hero-component.login form {
        margin: 0 auto;
        width: 490px
    }
}

@media (min-width: 899px) {
    .hero-component h2 {
        color:#191919;
        color: #fff;
        font-family: XSans-thin;
        font-size: 3.125rem;
        letter-spacing: .0125rem;
        line-height: 4.6875rem
    }

    .hero-component.login {
        background-image: url(../../images/library/hero/Home-Unauthenticated-Hero-1440.jpg);
        height: 585px
    }

    .hero-component.channels {
        background-image: url(../../images/library/hero/Channels-Hero-1440.jpg);
        height: 416px
    }

    .hero-component.channels>*,.hero-component.login>* {
        margin-bottom: 33px
    }

    .hero-component.channels p,.hero-component.login p {
        margin-left: auto;
        margin-right: auto;
        max-width: 720px
    }

    .hero-component.channels form,.hero-component.login form {
        margin: 0 auto;
        width: 490px
    }

    .hero-component.logged-in {
        background-image: url(../../images/library/hero/Home-Authenticated-Hero-1440.jpg)
    }

    .hero-component.logged-in .link-container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .hero-component.logged-in .link-container a {
        width: 157px
    }

    .hero-component.logged-in .link-container a.watch-now-btn {
        margin-right: 20px
    }

    .hero-component.logged-in.hbo-specific {
        background-image: url(../../images/library/hero/Home-Authenticated-Hero-1440-HBO.jpg)
    }

    .hero-component.logged-in.wifi-specific {
        background-image: url(../../images/library/hero/wifi_hero.png)
    }

    .modal .modal-content.results {
        padding-bottom: 14px;
        padding-top: 40px;
        width: 490px
    }

    .modal .modal-content.results h2 {
        padding-bottom: 30px
    }

    .modal .modal-content.results ul li {
        padding-left: 38px;
        padding-right: 38px
    }

    .modal .modal-content.no-results {
        width: 740px
    }

    .modal .modal-content.no-results p {
        margin-bottom: 30px
    }
}

@media (-webkit-min-device-pixel-ratio: 2),(min-resolution:2dppx) {
    .hero-component.login {
        background-image:url(../../images/library/hero/m_Home-Unauthenticated-Hero-1534.jpg)
    }

    .hero-component.channels {
        background-image: url(../../images/library/hero/m_Channels-Hero-1534.jpg)
    }

    .hero-component.logged-in {
        background-image: url(../../images/library/hero/m_Home-Authenticated-Hero-1534-HBO.jpg)
    }

    .hero-component.logged-in.hbo-specific {
        background-image: url(../../images/library/hero/m_Home-Authenticated-Hero-1534.jpg)
    }

    .hero-component.logged-in.wifi-specific {
        background-image: url(../../images/library/hero/wifi_hero.png)
    }

    .hero-component.upgrade {
        background-image: url(../../images/library/hero/m_Channel-Upgrade-Hero-1534.jpg)
    }
}


  