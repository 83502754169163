@charset "utf-8";

@font-face {
	font-family: 'XFINITYSansTT-Reg';
	src: local('XFINITYSansTT-Reg'),
	url('../../../public/resources/css/fonts/XFINITYSansTT-Reg.ttf') format('truetype');
}

@font-face {
    font-family: "XfinitySansIcon";
    src: url("../../../public/resources/css/fonts/xfinity_sans_icons_v1.14.eot");
    src: url("../../../public/resources/css/fonts/xfinity_sans_icons_v1.14.eot?#iefix") format("embedded-opentype"),
         url("../../../public/resources/css/fonts/xfinity_sans_icons_v1.14.woff") format("woff"),
         url("../../../public/resources/css/fonts/xfinity_sans_icons_v1.14.ttf") format("truetype"),
         url("../../../public/resources/css/fonts/xfinity_sans_icons_v1.14.svg#XfinitySansIcon") format("svg");
    font-weight: normal;
    font-style: normal;
}

.errorContainer {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #46434f;
	background-image: url('../../../public/resources/images/XTV-BG.png');
}

.pickererrorsection {
	width: 30%;
    text-align: center;
}

.icon-badge-nowifi:before {
	font-family: XfinitySansIcon;
  	content: "\e1e0";
  	font-size: 40px;
    color: #fff;
    border-radius: 50%;
    border: solid 2px #fff;
	padding: 10px 14px 10px;
   	display: inline-block;
}

.errorsectionrule {
	margin-top: 26px;
	border: 0px;
	height: 1px;
	width: 46px;
	background: #9b9b9b;
}

.errorsectiontext {
	font-family: XFINITYSansTT-Lgt;
	color: #fff;
	font-size: 18px;
	margin-top: 24px;
}

.errorimage {
	opacity: 0.8;
	filter: alpha(opacity=80);
}