@media (min-width: 768px) {
    .xoc {
        margin-bottom: 98px;
    }
}

@media (min-width: 383px) {
    .xoc .title {
        color: #191919;
        font-family: XSans-thin;
        font-size: 3.125rem;
        font-size: 50px;
        letter-spacing: .0125rem;
        letter-spacing: .2px;
        line-height: 4.6875rem;
        margin: 40px 12px;
        text-align: center;
    }
}

@media (min-width: 768px) {
    .xoc .media-container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

@media (min-width: 768px) {
    .xoc .media-container .media {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 57px;
    }
}

@media (min-width: 768px) {
    .xoc .media-container>* {
        width: 50%;
    }
}

@media (min-width: 768px) {
    .xoc .media-container .media picture {
        display: inline-block;
    }
}

@media (min-width: 768px) {
    .steps-banner {
        height: 366px;
    }
}
.steps-banner {
    background-color: #1f232e;
    color: #fff;
    text-align: center;
}
.steps-banner h2 {
    color: #191919;
    color: #fff;
    display: inline-block;
    font-family: XSans-thin;
    font-size: 1.875rem;
    letter-spacing: .0125rem;
    line-height: 2.8125rem;
    line-height: 3.4375rem;
    margin: 36px 0 28px;
}

@media (min-width: 768px) {
    .steps-banner ul {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-pack: distribute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: space-around;
        padding: 0;
    }
}
.steps-banner ul {
    padding: 0 50px 9px;
}

@media (min-width: 768px) {
    .steps-banner ul li.desktop {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media (min-width: 768px) {
    .steps-banner ul li.mobile {
        display: none;
    }
}

@media (min-width: 768px) {
    .steps-banner ul li {
        margin: 0;
        width: 190px;
    }
}

.steps-banner ul li {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto 41px;
}

@media (min-width: 768px) {
    .steps-banner .icon {
        margin: 0 auto 27px;
    }
}
.steps-banner .icon-laptop {
    height: 51px;
    width: 78px;
}
.steps-banner .icon {
    margin-bottom: 16px;
    margin-top: 14px;
}
.icon-laptop {
    /* background-image: url(../../images/svg/icon-laptop.svg); */
    background-image: url(../../../public/library/images/svg/icon-laptop.svg);
}
.icon-laptop, .icon-warning {
    background-repeat: no-repeat;
}

.steps-banner .step-desc {
    color: #191919;
    color: #fff;
    font-family: XSans-extra-light;
    font-size: 1.125rem;
    letter-spacing: .0125rem;
    line-height: 1.6875rem;
}
@media (min-width: 768px) {
    .steps-banner ul li.desktop .ctas {
        margin-top: 1px;
    }
}

.mobile-steps .step-btn-container, .steps-banner ul .ctas {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}
.steps-banner ul .ctas {
    margin-top: 40px;
}
@media (min-width: 768px) {
    .steps-banner .icon {
        margin: 0 auto 27px;
    }
}
.steps-banner .icon-download {
    height: 72px;
    width: 72px;
}

.icon-download, .icon-play {
    background-repeat: no-repeat;
}
.icon-download {
    background-image: url(../../../public/library/images/svg/icon-download.svg);
}

*, fieldset, figure, h1 {
    margin: 0;
}
* {
    padding: 0;
}

.xoc .copy h3, .xoc .copy p {
    letter-spacing: .0125rem;
}

.xoc .copy p {
    color: #44484c;
    font-family: XSans-light;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    margin-bottom: 41px;
    text-align: justify;
}

@media (min-width: 768px) {
    .about-banner {
        padding: 25px 0 46px;
    }
}

.about-banner {
    background-color: #0272b6;
    color: #fff;
    padding: 54px 40px 49px;
    text-align: center;
}

@media (min-width: 768px) {
    .about-banner h2 {
        line-height: 4.0625rem;
        width: auto;
    }
}
.about-banner h2 {
    color: #191919;
    color: #fff;
    font-family: XSans-thin;
    font-size: 1.875rem;
    letter-spacing: .0125rem;
    letter-spacing: .0625rem;
    line-height: 2.8125rem;
    margin: 0 auto 23px;
}
@media (min-width: 768px) {
    .about-banner p {
        margin: 10px auto 0;
        width: 554px;
    }
}
.about-banner a, .about-banner p {
    color: #44484c;
    color: #fff;
    font-family: XSans-light;
    font-size: 1.125rem;
    letter-spacing: .0125rem;
    line-height: 1.6875rem;
}
.about-banner p {
    margin: 0 auto;
}

.about-banner a {
    text-decoration: underline;
}

.about-banner a, .about-banner p {
    color: #44484c;
    color: #fff;
    font-family: XSans-light;
    font-size: 1.125rem;
    letter-spacing: .0125rem;
    line-height: 1.6875rem;
}
.about-banner a {
    text-decoration: underline;
  }