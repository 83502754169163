
 @media (min-width: 1050px) {
    .hide-laptop {
      display: none;
      visibility: hidden;
    }
  }
  
  @media (max-width: 1049px) {
    .small-screen-hide {
      display: none;
      visibility: hidden;
    }
  }

  @media (max-width: 898px) {
    .hide-on-mobile {
      display: none;
      visibility: hidden;
    }
  }