.hero-component {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 563px;
    padding: 0 37px;
    text-align: center;
    width: 100%
}

.hero-component h2 {
    color: #191919;
    font-size: 2.5rem;
    line-height: 3.75rem;
    line-height: 4.1875rem;
    margin-bottom: 26px
}

.hero-component h2,.hero-component p {
    color: #fff;
    font-family: XSans-thin;
    letter-spacing: .0125rem
}

.hero-component p {
    font-size: 1.125rem;
    line-height: 1.6875rem;
    margin-bottom: 44px
}

.hero-component .eyebrow {
    font-family: XSans-regular;
    font-size: .9375rem;
    font-weight: 700;
    letter-spacing: .03125rem;
    line-height: 1.375rem;
    text-transform: uppercase
}

/* .hero-component form {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: 100%
}

.hero-component form input {
    background-color: #fff;
    border: 1px solid #dde2e6;
    border-radius: 3px;
    font-family: XSans-thin;
    padding: 5px 10px;
    width: inherit
}

.hero-component form input:focus::-webkit-input-placeholder {
    color: transparent
}

.hero-component form input:focus::-moz-placeholder {
    color: transparent
}

.hero-component form input:focus:-ms-input-placeholder {
    color: transparent
}

.hero-component form input:focus::placeholder {
    color: transparent
}

.hero-component form button {
    background-image: url(../../../public/library/images/svg/icon-arrow.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 6px;
    height: 15px;
    position: absolute;
    right: 12px;
    top: 11px;
    width: 15px
}

.hero-component form button.active {
    background-image: url(../../../public/library/images/svg/icon-close.svg);
    background-size: 15px
}

.hero-component.login {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    background-image: url(../../../public/library/images/hero/m_Home-Unauthenticated-Hero-767.jpg);
    justify-content: center
}

.hero-component.login p {
    margin-left: -7px;
    margin-right: -7px
}

.hero-component.login form {
    margin-bottom: 50px
}

.hero-component.login input {
    padding-left: 36px
}

.hero-component.login .icon-search-black {
    height: 15px;
    left: 12px;
    position: absolute;
    top: 11px;
    width: 15px
} */

.hero-component.channels {
    background-image: url(../../../public/library/images/hero/m_Channels-Hero-767.jpg);
    height: 356px
}

.hero-component.channels,.hero-component.logged-in {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.hero-component.logged-in {
    background-image: url(../../../public/library/images/hero/m_Home-Authenticated-Hero-767-HBO.jpg);
    height: 512px
}

.hero-component.logged-in p {
    margin-bottom: 36px
}

.hero-component.logged-in .link-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.hero-component.logged-in a {
    margin-bottom: 10px
}

.hero-component.logged-in a.hide {
    display: none
}

.hero-component.logged-in a.show {
    display: inherit
}

.hero-component.logged-in.hbo-specific {
    background-image: url(../../../public/library/images/hero/m_Home-Authenticated-Hero-767.jpg)
}

.hero-component.logged-in.wifi-specific {
    background-image: url(../../../public/library/images/hero/wifi_hero.png)
}

.hero-component.video h2,.hero-component.video p {
    color: #000
}

.hero-component video {
    width: 100%
}

.hero-component.video {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    color: #000;
    height: auto;
    justify-content: center
}

.hero-component.video p {
    margin-bottom: 36px
}

.hero-component.video .link-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.hero-component.video a {
    margin-bottom: 10px
}

.hero-component.video a.hide {
    display: none
}

.hero-component.video a.show {
    display: inherit
}

.hero-component.upgrade {
    background-image: url(../../../public/library/images/hero/m_Channel-Upgrade-Hero-767.jpg);
    background-position-y: top;
    background-size: contain;
    color: #191919;
    height: auto;
    margin-bottom: 49px;
    padding-top: 137px
}

.hero-component.upgrade .eyebrow {
    margin-bottom: 12px
}

.hero-component.upgrade h2 {
    color: #191919;
    line-height: 3.125rem;
    margin-bottom: 30px
}

.hero-component.upgrade p {
    color: #191919;
    line-height: 1.5rem;
    margin-bottom: 0
}

.error form input {
    outline-color: #c90318;
    outline-width: 2px
}

.large {
    font-size: 2.5rem!important
}

.grid-component {
    background-color: #fff;
    padding: 39px 37px 0;
    text-align: center
}

.grid-component h2 {
    margin-bottom: 13px
}

.grid-component .copy {
    color: #191919;
    font-family: XSans-light;
    font-size: 1rem;
    letter-spacing: .0125rem;
    line-height: 1.5rem;
    margin-bottom: 15px
}

.grid-component .main-link {
    display: inline-block;
    margin-bottom: 34px
}

.grid-component .tile-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.grid-component .tile {
    position: relative
}

.grid-component.premium {
    margin-bottom: 69px
}

.grid-component.premium.home {
    margin-bottom: 54px
}

.grid-component.premium.home h2 {
    color: #191919;
    font-family: XSans-thin;
    font-size: 2.5rem;
    letter-spacing: .0125rem;
    line-height: 3.75rem;
    margin-bottom: 13px
}

.grid-component.premium .tile-grid {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.grid-component.premium .tile {
    background-color: #000;
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    width: 100%
}

.grid-component.premium .tile:not(:last-child) {
    margin-bottom: 10px
}

.grid-component.premium .tile:hover .bg-img {
    opacity: .7
}

.grid-component.premium .tile .bg-img {
    display: grid
}

.grid-component.premium .tile .bg-img img {
    border-radius: 3px
}

.grid-component.premium .tile-content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 0 50px;
    position: absolute;
    top: 0;
    width: 100%
}

.grid-component.premium .tile-content .logo {
    margin-bottom: 10px;
    width: auto
}

.grid-component.premium .tile-content .heading {
    height: 60px;
    line-height: 1.25rem
}

.grid-component.premium .tile-content .heading,.grid-component.premium .tile-content .tile-copy {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.grid-component.premium .tile-content .tile-copy {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    color: #191919;
    color: #fff;
    -ms-flex-direction: column;
    flex-direction: column;
    font-family: XSans-regular;
    font-size: .75rem;
    height: 36px;
    justify-content: center;
    letter-spacing: .0125rem;
    line-height: 1.125rem;
    margin: 0
}

.grid-component.premium .tile-content .tile-link {
    display: none
}

.grid-component.international {
    padding-bottom: 47px;
    padding-left: 11px;
    padding-right: 11px
}

.grid-component.international .tile-grid {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.grid-component.international .tile {
    border-radius: 3px;
    margin: 0 0 6px;
    width: calc(50% - 3px)
}

.grid-component.international .content-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%
}

.grid-component.international .placeholder {
    visibility: hidden
}

.grid-component.international .logo {
    height: 60px;
    margin-bottom: 10px;
    top: 25%;
    width: auto
}

.grid-component.international .tile-link {
    color: #44484c;
    color: #0272b6;
    font-family: XSans-regular;
    font-size: .875rem;
    letter-spacing: .0125rem;
    line-height: 1.3125rem;
    padding: 0 20px
}

.grid-component.upgrade.premium {
    margin-bottom: 4px;
    padding-top: 4px
}

.grid-component.upgrade h2 {
    margin-bottom: 45px
}

@media (min-width: 899px) {
    .hero-component h2 {
        color:#191919;
        color: #fff;
        font-family: XSans-thin;
        font-size: 3.125rem;
        letter-spacing: .0125rem;
        line-height: 4.6875rem
    }

    .hero-component.login {
        background-image: url(../../../public/library/images/hero/Home-Unauthenticated-Hero-1440.jpg);
        height: 585px
    }

    .hero-component.channels {
        background-image: url(../../../public/library/images/hero/Channels-Hero-1440.jpg);
        height: 416px
    }

    .hero-component.channels>* {
        margin-bottom: 33px
    }

    .hero-component.channels p {
        margin-left: auto;
        margin-right: auto;
        max-width: 720px
    }

    .hero-component.channels form {
        margin: 0 auto;
        width: 490px
    }

    .hero-component.logged-in {
        background-image: url(../../../public/library/images/hero/Home-Authenticated-Hero-1440.jpg)
    }

    .hero-component.logged-in .link-container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .hero-component.logged-in .link-container a {
        width: 157px
    }

    .hero-component.logged-in .link-container a.watch-now-btn {
        margin-right: 20px
    }

    .hero-component.logged-in.hbo-specific {
        background-image: url(../../../public/library/images/hero/Home-Authenticated-Hero-1440-HBO.jpg)
    }

    .hero-component.logged-in.wifi-specific {
        background-image: url(../../../public/library/images/hero/wifi_hero.png)
    }

    .hero-component.upgrade {
        background-image: url(../../../public/library/images/hero/Channel-Upgrade-Hero-1440.jpg);
        background-size: cover;
        color: #191919;
        height: 485px;
        margin-bottom: 46px;
        padding-top: 295px
    }

    .hero-component.upgrade .eyebrow {
        margin: 0 0 33px
    }

    .hero-component.upgrade p {
        width: 650px
    }

    .grid-component h2 {
        margin-bottom: 13px
    }

    .grid-component.premium {
        margin-bottom: 58px
    }

    .grid-component.premium .copy {
        margin-bottom: 26px;
        @apply --style-body2-lvp}

    .grid-component.premium .main-link {
        margin-bottom: 23px
    }

    .grid-component.premium .tile-grid {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-pack: distribute;
        -ms-flex-direction: row;
        flex-direction: row;
        justify-content: space-around
    }

    .grid-component.premium .tile {
        display: inline-table;
        margin-bottom: 11px;
        width: calc(33.33333% - 11px)
    }

    .grid-component.premium .tile-content {
        background: -webkit-gradient(linear,left top,left bottom,color-stop(70%,transparent),color-stop(90%,#000));
        background: linear-gradient(180deg,transparent 70%,#000 90%);
        padding: 0 20px
    }

    .grid-component.premium .tile-content .logo {
        margin-bottom: 3px;
        margin-top: -15px
    }

    .grid-component.premium .tile-content .heading {
        height: auto;
        margin-bottom: 5px
    }

    .grid-component.premium .tile-content .tile-copy {
        line-height: 1rem
    }

    .grid-component.premium .tile-content .tile-link {
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        align-items: center;
        border-top: 1px solid #868f96;
        bottom: 0;
        color: #44484c;
        color: #2b9cd8;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-family: XSans-regular;
        font-size: .875rem;
        height: 23%;
        justify-content: center;
        left: 50%;
        letter-spacing: .0125rem;
        line-height: 1.3125rem;
        padding: 0 0 4px;
        position: absolute;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
        width: 78%
    }

    .grid-component.international {
        padding-bottom: 41px
    }

    .grid-component.international .tile-grid {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .grid-component.international .tile {
        margin: 0 6px 12px;
        width: calc(20% - 12px)
    }
}

@media (-webkit-min-device-pixel-ratio: 2),(min-resolution:2dppx) {
    .hero-component.login {
        background-image:url(../../../public/library/images//hero/m_Home-Unauthenticated-Hero-1534.jpg)
    }

    .hero-component.channels {
        background-image: url(../../../public/library/images/hero/m_Channels-Hero-1534.jpg)
    }

    .hero-component.logged-in {
        background-image: url(../../../public/library/images/hero/m_Home-Authenticated-Hero-1534-HBO.jpg)
    }

    .hero-component.logged-in.hbo-specific {
        background-image: url(../../../public/library/images/hero/m_Home-Authenticated-Hero-1534.jpg)
    }

    .hero-component.logged-in.wifi-specific {
        background-image: url(../../../public/library/images/hero/wifi_hero.png)
    }

    .hero-component.upgrade {
        background-image: url(../../../public/library/images/hero/m_Channel-Upgrade-Hero-1534.jpg)
    }
}

@media (min-width: 768px) and (-webkit-min-device-pixel-ratio:2),(min-width:768px) and (min-resolution:2dppx) {
    .hero-component.login {
        background-image:url(../../../public/library/images/hero/Home-Unauthenticated-Hero-2880.jpg)
    }

    .hero-component.channels {
        background-image: url(../../../public/library/images/hero/Channels-Hero-2880.jpg)
    }

    .hero-component.logged-in {
        background-image: url(../../../public/library/images/hero/Home-Authenticated-Hero-2880.jpg)
    }

    .hero-component.logged-in.hbo-specific {
        background-image: url(../../../public/library/images/hero/Home-Authenticated-Hero-2880-HBO.jpg)
    }

    .hero-component.logged-in.wifi-specific {
        background-image: url(../../../public/library/images/hero/wifi_hero.png)
    }

    .hero-component.upgrade {
        background-image: url(../../../public/library/images/hero/Channel-Upgrade-Hero-2880.jpg)
    }
}

@media (min-width: 900px) {
    .grid-component.premium .tile-content .heading,.grid-component.premium .tile-content .logo {
        margin-bottom:6%
    }
}
