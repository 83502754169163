.inner-column {
    margin: 0 auto;
    max-width: 1024px;
  }
.sitemap {
    margin: 46px 10px;
  }
  
  .sitemap h2 {
    color: #191919;
    font-family: XSans-thin;
    font-size: 1.875rem;
    letter-spacing: .0125rem;
    line-height: 2.8125rem;
    margin: 0 20px 26px;
  }

  .sitemap h3 {
    margin-bottom: 18px;
  }

  .section-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .section-block .left, .section-block .right {
    border-top: 1px solid #dde2e6;
    padding: 31px 10px 53px 26px;
  }

  .links-section a, .sitemap h3 {
    color: #191919;
    font-family: XSans-extra-light;
    font-size: 1.25rem;
    letter-spacing: .0125rem;
    line-height: 1.875rem;
  }
  .sitemap h3 {
    margin-bottom: 18px;
  }
  .links-section a {
    color: #0272b6;
  }
  .links-section a, .sitemap h3 {
    color: #191919;
    font-family: XSans-extra-light;
    font-size: 1.25rem;
    letter-spacing: .0125rem;
    line-height: 1.875rem;
  }
  .section-block .left, .section-block .right {
    border-top: 1px solid #dde2e6;
    padding: 31px 10px 53px 26px;
  }
@media (min-width: 768px) {
    .sitemap {
        margin: 85px auto;
      }
    .sitemap h2 {
      color: #191919;
      font-family: XSans-thin;
      font-size: 2.5rem;
      letter-spacing: .0125rem;
      line-height: 3.75rem;
      margin: 0 0 30px;
    }
    .section-block {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        border-top: 1px solid #dde2e6;
        -ms-flex-direction: row;
        flex-direction: row;
      }
      .section-block .left, .section-block .right {
        border: none;
      }
      .section-block .left {
        margin-right: 180px;
      }
      .links-section a {
        color: #44484c;
        font-family: XSans-light;
        font-size: 1.125rem;
        letter-spacing: .0125rem;
        line-height: 1.6875rem;
        line-height: 2.5rem;
      }
      .section-block .left, .section-block .right {
        border: none;
      }
  }
 