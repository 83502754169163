.c1-wrapper {
    text-align: center;
    background-color: #FFFFFF;
    border: 1px solid #6138f5;
    border-radius: 10px;
    width: 290px;
    height: 350px;
    padding: 20px;
    margin: 20px;
    float: left;
    filter: drop-shadow(0px 4px 4px #00000044);
}

.c1-wrapper-header {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0px;
}