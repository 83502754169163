.wifiFaq {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: 10px;
    margin-right: 10px;
}

.wifiProducts {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}

.wifiLink{
    padding-left: 8px; font-style: normal; font-weight: 600; font-size: 14px; line-height: 24px; color: rgb(97, 56, 245); text-decoration: underline !important;
}

.dpskContainer {
    text-align:center;  display: flex;   flex-direction: column;   align-items: center;   padding: 16px 64px;      min-height: 56px; max-height:100px;   background: #E3E0F4;
}

/* .grid-component h2 {
    font-size: 2em;
    font-weight: bolder !important;
    color: #6138f5;
    margin-top: 40px;
} */