#termsContinue {
    background: #ffa700;
    border: none;
    border-radius: 40px;
    color: #000;
    padding: 10px 30px
}

#termsContinue.disabled {
    cursor: default !important;
    opacity: .5
}

.modal-footer-terms {
    overflow: auto;
    /* padding: 20px 10px 20px 20px; */
    padding: 4vw !important;
    text-align: center !important;
    width: 100%
}

.popupcheckctrls-terms {
    padding: 10px 0 20px
}