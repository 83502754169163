.product-details-component {
    padding-top: 10px;
    text-align: center
}

.product-details-component .disclaimer {
    color: #44484c;
    color: #191919;
    font-family: XSans-medium;
    font-family: XSans-regular;
    font-size: .625rem;
    letter-spacing: .01063rem;
    line-height: .875rem
}

.product-details-component .details {
    padding: 0 50px
}

.product-details-component .details.text-only {
    margin-bottom: 42px
}

.product-details-component .details .add-package {
    margin-bottom: 40px
}

.product-details-component .details .add-package h2 {
    margin-bottom: 29px
}

.product-details-component .details .add-package img {
    width: auto
}

.product-details-component .details .add-package p {
    color: #191919;
    font-family: XSans-light;
    font-size: 1rem;
    letter-spacing: .0125rem;
    line-height: 1.5rem;
    margin-bottom: 38px;
    max-width: 80%;
    text-align: left;
}

.product-details-component .details .add-package .eyebrow {
    font-family: XSans-regular;
    font-size: .9375rem;
    font-weight: 700;
    letter-spacing: .03125rem;
    line-height: 1.375rem;
    margin-bottom: 6px
}

.product-details-component .details .add-package .price {
    color: #191919;
    font-family: XSans-extra-light;
    font-size: 1.5rem;
    letter-spacing: .0125rem;
    line-height: 2.25rem;
    margin-bottom: 19px
}

.product-details-component .details .add-package>a {
    display: inline-block;
    font-size: 1rem
}

.product-details-component .details .add-package .disabled {
    background-color: #0272b6;
    border: none;
    border-radius: 40px;
    color: #fff;
    cursor: default;
    opacity: .4;
    padding: 10px 30px
}

.product-details-component .details .add-package ul {
    list-style: none
}

.product-details-component .details .add-package ul li:before {
    content: "✓"
}

.product-details-component .featured-shows {
    margin-bottom: 40px
}

.product-details-component .featured-shows h2 {
    color: #44484c;
    font-family: XSans-light;
    font-size: 1rem;
    font-size: 16px;
    letter-spacing: .0125rem;
    line-height: 1.5rem;
    margin-bottom: 14px
}

.product-details-component .featured-shows ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 25px
}

.product-details-component .featured-shows ul li:not(:last-child) {
    margin-bottom: 20px
}

.product-details-component .featured-shows ul li .embed-container {
    height: 0;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative
}

.product-details-component .featured-shows ul li .embed-container embed,
.product-details-component .featured-shows ul li .embed-container iframe,
.product-details-component .featured-shows ul li .embed-container object {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.product-details-component .featured-shows ul img {
    display: block
}

.product-details-component .featured-shows p {
    padding: 0 60px
}

@media (min-width:768px) {
    .product-details-component {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0 auto 100px;
        max-width: 1072px;
        padding: 94px 24px 0
    }

    .product-details-component .details {
        padding-left: 0;
        padding-right: 0;
        padding-top: 38px;
        text-align: left;
        width: 100%
    }

    .product-details-component .details.text-only {
        margin-bottom: 0;
        width: 50%
    }

    .product-details-component .details .add-package h2 {
        margin-bottom: 21px
    }

    .product-details-component .details .add-package h2 ul {
        list-style: none
    }

    .product-details-component .details .add-package h2 ul li:before {
        content: "✓"
    }

    .product-details-component .featured-shows {
        margin-right: 78px;
        max-width: 540px;
        width: 54%
    }

    .product-details-component .featured-shows h2 {
        text-align: left
    }

    .product-details-component .featured-shows ul {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .product-details-component .featured-shows .primary {
        width: 100%
    }

    .product-details-component .featured-shows .secondary {
        width: calc(50% - 10px)
    }

    .product-details-component .featured-shows .secondary:last-child {
        margin-left: 20px
    }

    .product-details-component .featured-shows p {
        padding: 0;
        text-align: left
    }
    .product-details-component .wifi-img {
        max-width: 640px;
    }
    
    .product-details-component .wifi-img .primary {
        width: 100%
    }
}
@media (max-width:767px) {
    .product-details-component .details .add-package p {
        width: 100%
    }
}